'use strict';

import _ from 'underscore';
import React from 'react';
import Utils from '../../jskit/general/Utils';
import Formatter from '../../jskit/general/Formatter';
import ReactUtils from '../../jskit/react/ReactUtils';
import CheckBox from '../../jskit/react/forms/CheckBox';
import ResponseTimeChart from '../../jskit/react/ResponseTimeChart';
import ServiceItemActions from './ServiceItemActions';
import moment from 'moment-timezone';

class ServiceListItem extends React.PureComponent {
  render() {
    const checkStatus = this.getStatusClassNameAndTitle(this.props.item);

    const uptime = Formatter.percentage(this.props.performanceMetrics.uptime_24h, 4);
    const response_time = this.renderResponseTime(
      this.props.item,
      this.props.performanceMetrics,
      this.props.serviceDef
    );

    return (
      <tr>
        <td className="text-right pr-0">
          <i className={checkStatus.className} title={checkStatus.title} />
          {this.props.hasWriteAccess ? (
            <CheckBox
              fieldName={`select-${this.props.id}`}
              labelText=" "
              value={this.props.checked}
              onChange={this.props.onCheck}
              divCSSClass="d-inline-block"
            />
          ) : null}
        </td>
        <td>{this.renderServiceName(this.props.item)}</td>
        {this.renderSecondaryIcons(this.props.item)}
        <td>
          <div className="tag-container-vertical">{this.renderTags(this.props.item)}</div>
        </td>
        <td>{this.props.item.monitoring_service_type_display}</td>
        <td>{this.renderContacts(this.props.item)}</td>
        <td className="text-center">{uptime}</td>
        <td className="text-center">{response_time}</td>
        <td className="actions-right">
          <ServiceItemActions
            item={this.props.item}
            hasWriteAccess={this.props.hasWriteAccess}
            onEdit={this.props.onEdit}
            onMaintenance={this.props.onMaintenance}
            onResume={this.props.onResume}
            onPause={this.props.onPause}
            onDuplicate={this.props.onDuplicate}
            onDelete={this.props.onDelete}
            extraListProps={this.props.extraListProps}
            hasAdminAccess={this.props.hasAdminAccess}
          />
        </td>
      </tr>
    );
  }

  getStatusClassNameAndTitle(item) {
    let cls = 'status';
    let title = '';

    if (item.supports_downtime_alerting === false) {
      cls += ' status-muted';
      title = 'This check does not support downtime alerting.';
    } else if (item.is_paused) {
      cls += ' status-paused';
      title = 'This check is currently paused.';
    } else if (!item.cached_state_is_up) {
      cls += ' status-down';
      title = 'This check is currently down.';
    } else {
      cls += ' status-up';
      title = 'This check is currently up.';
    }

    return {
      className: cls,
      title: title,
    };
  }

  renderServiceName(item) {
    const nameAndAddress = Formatter.serviceNameAndAddress(item);
    var link = Formatter.serviceReportURL(
      item,
      this.props.extraListProps.defaultDrillDownURL,
      this.props.extraListProps.drillDownURLs
    );
    return (
      <React.Fragment>
        <a href={link} className="d-block text-dark font-weight-semibold">
          {nameAndAddress.name}
        </a>
        <a href={link} title={nameAndAddress.address} className="d-block unstyled">
          <span className="text-light">{nameAndAddress.addressShort}</span>
        </a>
      </React.Fragment>
    );
  }

  renderSecondaryIcons(item) {
    const icons = [];
    const titles = [];
    if (item.is_under_maintenance) {
      const title =
        item.schedule_state === 'SCHEDULED'
          ? 'This check is currently under scheduled maintenance.'
          : 'This check is currently under maintenance until manually re-enabled.';
      icons.push(<i className="under-maintenance" key="maintenance" />);
      titles.push(title);
    } else if (item.schedule_state === 'SCHEDULED') {
      icons.push(<i className="has-schedule" key="maintenance" />);
      titles.push('This check has scheduled maintenance windows.');
    }
    if (item.supports_downtime_alerting === false) {
      icons.push(<i className="with-no-downtime-alerting" key="no-downtime-alerting" />);
      titles.push(
        'Reporting on the group’s average uptime% will not issue alerts for downtime within this group check.'
      );
    }
    const tdClassName = titles.length ? 'with-icons' : null;
    return (
      <td title={titles.join(' ')} className={tdClassName}>
        {icons}
      </td>
    );
  }

  renderTags(item) {
    return item.tags.map((tag, index) => (
      <span key={index} className="tag" style={{backgroundColor: tag.color_hex}}>
        {tag.tag}
      </span>
    ));
  }

  renderContacts(item) {
    return <small className="text-light">{_.pluck(item.contact_groups, 'name').join(', ')}</small>;
  }

  renderResponseTime(item, performanceMetrics, serviceDef) {
    // If you're changing this method, please make sure to make corresponding changes
    // to `format_response_time` function in apps/lib/templatetags/uptime_tags.py
    let response = '';

    if (item.is_paused) {
      return response;
    }

    if (['RESPONSE_TIME', 'JITTER_DELTA', 'CUSTOM'].indexOf(serviceDef.performance_data_type) >= 0) {
      if (performanceMetrics.response_time_datapoints) {
        return (
          <ResponseTimeChart
            width={120}
            height={45}
            responseTimeSLA={item.msp_response_time_sla}
            data={performanceMetrics.response_time_datapoints}
          />
        );
      } else {
        response = item.cached_response_time >= 0.0 ? item.cached_response_time.toFixed(3) + 's' : '';
      }
    } else if (serviceDef.performance_data_type === 'DAYS_TO_EXPIRY' && item.cached_response_time >= 1.0) {
      const days = item.cached_response_time.toFixed(0);
      const validUntil = Formatter.shortDate(moment.utc().add(days, 'day'));
      response = (
        <div className="d-flex flex-column">
          <span className="font-weight-semibold">
            Expires: {days} {Formatter.pluralize(days, 'day', 'days')}
          </span>
          <span className="font-13">{validUntil}</span>
        </div>
      );
    } else if (item.monitoring_service_type == 'PAGESPEED' && item.cached_uptime_grade) {
      response = 'Grade: ' + item.cached_uptime_grade;
    }

    return response;
  }
}

export default class ServiceList extends React.Component {
  constructor(props) {
    super(props);

    Utils.autoBindClass(this);

    this.state = {
      sort: [],
      selected: {},
    };
  }

  getSort() {
    return _.clone(this.state.sort);
  }

  setSort(s) {
    this.setState({sort: s});
  }

  numSelected() {
    return _.size(this.state.selected);
  }

  allAreSelected() {
    return this.props.items.length > 0 && _.size(this.state.selected) === this.props.items.length;
  }

  noneAreSelected() {
    return _.size(this.state.selected) === 0;
  }

  getSelectedIndices() {
    return _.keys(_.pick(this.state.selected, _.identity));
  }

  clearSelections() {
    this.setState({selected: {}});
  }

  toggleSelectAll() {
    const allWereSelected = this.allAreSelected();

    this.state.selected = {};

    if (!allWereSelected) {
      for (const i in _.range(this.props.items.length)) {
        this.state.selected[i] = true;
      }
    }

    this.setState({selected: this.state.selected});
  }

  handleSort(field, e) {
    e.preventDefault();
    const currentSort = (this.state.sort.length && this.state.sort[0]) || '';

    if (currentSort === field) {
      this.state.sort[0] = '-' + this.state.sort[0];
    } else if (currentSort === '-' + field) {
      this.state.sort[0] = this.state.sort[0].substr(1);
    } else {
      this.state.sort.unshift(field);
      this.state.sort = this.state.sort.slice(0, 2);
    }

    this.setState({sort: this.state.sort}, this.props.onSort);
  }

  handleCheck(index, e) {
    if (this.state.selected[index]) {
      delete this.state.selected[index];
    } else {
      this.state.selected[index] = true;
    }

    this.setState({selected: this.state.selected});

    if (this.props.onCheck) {
      this.props.onCheck();
    }
  }

  handleCheckAll(e) {
    this.toggleSelectAll();

    if (this.props.onCheck) {
      this.props.onCheck();
    }
  }

  render() {
    if (!this.props.hasLoaded) {
      return null;
    }

    let items = null;

    if (this.props.items.length === 0) {
      if (!this.props.hasCreatedChecks && this.props.hasWriteAccess) {
        items = (
          <tr>
            <td colSpan="8" className="px-4 py-4">
              You have not yet added any checks; please go ahead and{' '}
              <a href="#" onClick={this.props.onAddCheck}>
                add a check
              </a>{' '}
              now!
            </td>
          </tr>
        );
      } else {
        items = (
          <tr>
            <td colSpan="8" className="px-4 py-4">
              No checks found matching the filter(s) above.
            </td>
          </tr>
        );
      }
    } else {
      items = this.props.items.map((item, index) => (
        <ServiceListItem
          id={item.id}
          key={item.id}
          hasWriteAccess={this.props.hasWriteAccess}
          hasAdminAccess={this.props.hasAdminAccess}
          item={item}
          performanceMetrics={this.props.performanceMetrics[item.id] || {}}
          serviceDef={this.props.serviceDefs[item.monitoring_service_type]}
          extraListProps={this.props.extraListProps}
          checked={this.state.selected[index]}
          onPause={this.props.onPauseResume.bind(null, index, 'pause')}
          onResume={this.props.onPauseResume.bind(null, index, 'resume')}
          onCheck={this.handleCheck.bind(null, index)}
          onEdit={this.props.onEdit.bind(null, index)}
          onMaintenance={this.props.onMaintenance.bind(null, index)}
          onDuplicate={this.props.onDuplicate.bind(null, index)}
          onDelete={this.props.onDelete.bind(null, index)}
        />
      ));
    }

    const sortClass = (field) => {
      const asc = field === this.state.sort[0];
      const desc = '-' + field === this.state.sort[0];
      return ReactUtils.cssClass('unstyled sort-caret', {
        desc: desc,
        active: asc || desc,
      });
    };

    return (
      <div className="white-block white-block-border mb-4">
        <table className="table table-responsive-md data-table data-table-with-status mb-0" role="grid">
          <thead>
            <tr>
              <th className="text-right pr-0" style={{width: '50px'}}>
                {this.props.hasWriteAccess ? (
                  <CheckBox
                    fieldName="select-all"
                    labelText=" "
                    value={this.allAreSelected()}
                    onChange={this.handleCheckAll}
                    divCSSClass="d-inline-block"
                  />
                ) : null}
              </th>
              <th>
                <a onClick={this.handleSort.bind(null, 'name')} className={sortClass('name')} href="#">
                  Name
                </a>
              </th>
              <th className="with-icons"></th>
              <th>Tags</th>
              <th>
                <a
                  onClick={this.handleSort.bind(null, 'monitoring_service_type')}
                  className={sortClass('monitoring_service_type')}
                  href="#"
                >
                  Type
                </a>
              </th>
              <th>Contacts</th>
              <th className="text-center">Uptime (24h)</th>
              <th className="text-center">
                <a
                  onClick={this.handleSort.bind(null, 'response_time')}
                  className={sortClass('response_time')}
                  href="#"
                >
                  Response
                </a>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>{items}</tbody>
        </table>
      </div>
    );
  }
}
