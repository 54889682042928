'use strict';

import React from 'react';
import _ from 'underscore';
import Formatter from '../../jskit/general/Formatter';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import ReactUtils from '../../jskit/react/ReactUtils';
import SelectBox from '../../jskit/react/forms/SelectBox';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import {AlertDetailsModal} from '../../reports/common/AlertDetailsModal';
import MessageBox from '../../js/global/messagebox';

const ServiceDiagnosticsStatus = {
  RUNNING: 'RUNNING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

const TaskStatus = {
  NEW: 'NEW',
  RUNNING: 'RUNNING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

export default class RealTimeAnalysisController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      realTimeCheckStatus: null,
      alertDetailsAlertId: null,
      alertsShowMore: false,
      formData: {
        traceroute_location:
          this.props.tracerouteLocations && this.props.tracerouteLocations.length > 0
            ? this.props.tracerouteLocations[0][0]
            : null,
      },
      tracerouteStatus: null,
      tracerouteResult: '',
      tracerouteError: null,
    };
  }

  componentDidMount() {
    this.loadRealTimeCheckStatus();
  }

  loadRealTimeCheckStatus(startNew) {
    const method = startNew ? 'POST' : 'GET';

    new Ajax().sendRequest({
      method: method,
      url: this.props.urls.loadNagiosStatus,
      decoder: 'json',
      success: function (data) {
        if (
          this.state.realTimeCheckStatus &&
          this.state.realTimeCheckStatus.status === ServiceDiagnosticsStatus.RUNNING &&
          data &&
          data.status === ServiceDiagnosticsStatus.FAILED
        ) {
          MessageBox.alertBox('Loading the real-time status failed, please try again later.', 'Real-Time Check Status');
        }

        this.setState({realTimeCheckStatus: data});

        if (data && data.status === ServiceDiagnosticsStatus.RUNNING) {
          setTimeout(this.loadRealTimeCheckStatus.bind(this), 2500);
        }
      }.bind(this),
    });
  }

  handleLoadRealTimeCheckStatusClick(e) {
    e.preventDefault();
    this.loadRealTimeCheckStatus(true);
  }

  handleAlertDetailsClick(alertId, e) {
    e.preventDefault();
    this.setState({alertDetailsAlertId: alertId}, () => {
      this.refs.alertDetailsModal.showModal();
    });
  }

  handleDomainHealthCheckClick(e) {
    e.preventDefault();
    this.refs.domainHealthCheckForm.submit();
  }

  handleTracerouteStart(e) {
    e.preventDefault();
    new Ajax().post({
      url: this.props.urls.traceroute,
      disable: '.traceroute button',
      data: {
        service_id: this.props.service.id,
        server_id: this.state.formData.traceroute_location,
      },
      encoder: 'json',
      decoder: 'json',
      success: function (response, textStatus, jqXHR, options) {
        if (response.success) {
          this.setState({
            tracerouteStatus: TaskStatus.NEW,
          });
          this.loadTimer = 0;
          this.pollResults(response.data.task_id);
        } else {
          this.setState({
            tracerouteStatus: TaskStatus.FAILED,
            tracerouteError: 'Error running traceroute: ' + response.error,
          });
        }
      }.bind(this),
    });
  }

  handleShowMoreClick(e) {
    e.preventDefault();
    this.setState({alertsShowMore: !this.state.alertsShowMore});
  }

  pollResults(task_id) {
    if (this.loadTimer >= 120000) {
      this.setState({
        tracerouteStatus: TaskStatus.FAILED,
        tracerouteError: 'Task execution timeout',
      });
      return;
    }

    new Ajax().get({
      url: this.props.urls.traceroute,
      data: {
        task_id: task_id,
      },
      decoder: 'json',
      success: function (response, textStatus, jqXHR, options) {
        if (response.success) {
          this.setState({
            tracerouteStatus: response.data.status,
            tracerouteResult: response.data.result,
            tracerouteError: response.data.error,
          });
          var textarea = document.getElementById('terminal');
          if (textarea) {
            textarea.scrollTop = textarea.scrollHeight;
          }
        } else {
          this.setState({
            tracerouteStatus: TaskStatus.FAILED,
            tracerouteError: 'Error running traceroute: ' + response.error,
          });
        }

        if (response.data.status == TaskStatus.NEW || response.data.status == TaskStatus.RUNNING) {
          this.loadTimer += 2500;
          setTimeout(this.pollResults.bind(this), 2500, task_id);
        }
      }.bind(this),
    });
  }

  render() {
    const service = this.props.service;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <div className="white-block white-block-border white-block-divider p-4">
              <div className="row">
                <div className="col-md-6 mb-2 mb-md-0">
                  <h4 className="font-24 text-primary font-weight-medium mt-1 mb-0">
                    {service.display_name}
                    <small className="d-block font-13 text-dark-4">{service.device.address}</small>
                  </h4>
                </div>
              </div>
            </div>
            {this.renderStatusPerLocation()}
            {this.renderRecentAlerts()}
            {this.renderTraceroute()}
          </div>
        </div>
        <AlertDetailsModal
          ref="alertDetailsModal"
          stepDefs={this.props.stepDefs}
          alertId={this.state.alertDetailsAlertId}
          {...this.props.alertDetailsProps}
        />
      </React.Fragment>
    );
  }

  renderStatusPerLocation() {
    const realTimeCheckStatus = this.state.realTimeCheckStatus;
    let statusPerServer = {};

    if (this.props.statusPerLocation.length === 0) {
      // This is a no-location check such as RUM
      return null;
    }

    if (realTimeCheckStatus && realTimeCheckStatus.data) {
      statusPerServer = _.object(realTimeCheckStatus.data.map((m) => [m.monitoring_server_id, m]));
    }

    let loadStatusButton = null;
    if (realTimeCheckStatus && realTimeCheckStatus.status === ServiceDiagnosticsStatus.RUNNING) {
      loadStatusButton = <i className="fas fa-spinner fa-spin fa-lg mr-5 text-muted" />;
    } else if (!this.props.service.is_paused) {
      loadStatusButton = (
        <button onClick={this.handleLoadRealTimeCheckStatusClick} className="btn btn-primary btn-sm">
          Load Real-Time Check Status
        </button>
      );
    }

    return (
      <div className="white-block white-block-border white-block-divider p-4 py-5">
        <div className="d-flex flex-wrap justify-content-between align-items-baseline mb-4">
          <h4 className="font-weight-medium mb-0">Location Status</h4>
          {loadStatusButton}
        </div>
        <table className="table table-responsive-md status-table mb-0">
          <thead>
            <tr>
              <th>Location</th>
              <th>Assigned Probe Server</th>
              <th className="text-center">State</th>
              <th>Last Alert Date</th>
              <th>Last Alert Details</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.statusPerLocation.map((s) =>
              this.renderStatusForOneLocation(s, statusPerServer[(s.monitoring_server || {}).id])
            )}
          </tbody>
        </table>
      </div>
    );
  }

  renderStatusForOneLocation(status, realTime) {
    const hasLatestAlert = !!status.latest_alert;
    const latestAlert = status.latest_alert || {};
    const updown = !hasLatestAlert || latestAlert.is_up ? 'up' : 'down';

    let specialStateText = null;
    if (this.props.service.is_paused) {
      specialStateText = 'Check paused';
    } else if (!status.monitoring_server) {
      specialStateText = 'Pending reconfiguration...';
    }

    const rows = [
      <tr key={status.monitoring_group.id}>
        <td>
          <span className={'status ' + (hasLatestAlert ? updown : 'muted')}></span>
          <span> {status.monitoring_group.location}</span>
        </td>
        <td>
          {!specialStateText ? (
            <React.Fragment>
              {status.monitoring_server.name}
              <br />
              <small className="text-nowrap text-muted font-monospace font-12">
                {status.monitoring_server.address_ipv4}
              </small>
              <br />
              <small className="text-nowrap text-muted font-monospace font-12">
                {status.monitoring_server.address_ipv6}
              </small>
            </React.Fragment>
          ) : (
            <em className="text-muted">{specialStateText}</em>
          )}
        </td>
        <td className={'text-center text-' + Formatter.alertStateCSSClass(latestAlert.state)}>{latestAlert.state}</td>
        <td>{latestAlert.created_at ? Formatter.shortDateTime(latestAlert.created_at) : null}</td>
        <td className="text-word-wrap">{latestAlert.output}</td>
        <td>{/*<button className="btn btn-outline-primary btn-xs">Request Traceroute</button>*/}</td>
      </tr>,
    ];

    if (realTime) {
      let inner = null;

      if (realTime.error) {
        inner = (
          <td colSpan="5">
            <h5 className="text-muted">
              Live Status: <small className="text-danger font-weight-normal">Failed to query check server.</small>
            </h5>
          </td>
        );
      } else if (realTime.status === 'NOT_CONFIGURED') {
        inner = (
          <td colSpan="5">
            <h5 className="text-muted">
              Live Status: <small className="font-weight-normal">Awaiting configuration to probe server.</small>
            </h5>
          </td>
        );
      } else if (realTime.status === 'PENDING') {
        inner = (
          <td colSpan="5">
            <h5 className="text-muted">
              Live Status: <small className="font-weight-normal">Waiting for first check.</small>
            </h5>
          </td>
        );
      } else {
        inner = (
          <React.Fragment>
            <td colSpan="2" className="align-top">
              <h5>
                Live Status{' '}
                <small className="text-muted font-weight-normal">
                  as at {Formatter.shortDateTime(realTime.created_at)}
                </small>
              </h5>
              <p className="mb-2">
                <span className={'font-weight-bold text-' + Formatter.alertStateCSSClass(realTime.status)}>
                  {realTime.status}{' '}
                </span>
                {realTime.status !== 'OK' ? (
                  <span>
                    (retry {realTime.current_attempt - 1} of {realTime.max_attempts - 1}){' '}
                  </span>
                ) : null}
                <span>since {Formatter.shortDateTime(realTime.last_state_change)}</span>
              </p>
              <p className="mb-0">
                <strong>Next Scheduled Check: </strong>
                {Formatter.shortDateTime(realTime.next_check)}
              </p>
            </td>
            <td colSpan="3" className="align-top">
              <p className="mt-1 mb-2">
                <strong>Last Checked: </strong>
                {Formatter.shortDateTime(realTime.last_check)}
              </p>
              <div className="card card-light-2 card-body-slim d-inline-block">
                <pre className="mb-0">{realTime.plugin_output}</pre>
              </div>
            </td>
          </React.Fragment>
        );
      }

      rows.push(
        <tr key={status.monitoring_group.id + '-realtime'}>
          <td className="border-top-0"></td>
          {inner}
        </tr>
      );
    }

    return rows;
  }

  renderRecentAlerts() {
    const title = this.props.statusPerLocation.length > 0 ? 'Recent Alerts Per Location' : 'Recent Alerts';
    var moreRows = null;
    if (this.props.recentAlerts.length > 10) {
      moreRows = this.props.recentAlerts.slice(10).map(this.renderOneRecentAlert);
      moreRows = (
        <React.Fragment>
          <tbody className={ReactUtils.cssClass({'d-none': this.state.alertsShowMore})} style={{border: 0}}>
            <tr>
              <td colSpan="8">
                <a className="more" href="#" onClick={this.handleShowMoreClick}>
                  Show {this.state.alertsShowMore ? 'less' : 'more'} alerts
                </a>
              </td>
            </tr>
          </tbody>
          <tbody className={ReactUtils.cssClass({'d-none': !this.state.alertsShowMore})} style={{border: 0}}>
            {moreRows}
            <tr>
              <td colSpan="8">
                <a className="more" href="#" onClick={this.handleShowMoreClick}>
                  Show {this.state.alertsShowMore ? 'less' : 'more'} alerts
                </a>
              </td>
            </tr>
          </tbody>
        </React.Fragment>
      );
    }
    return (
      <div className="white-block white-block-border white-block-divider p-4 py-5">
        <h4 className="font-weight-medium mb-4">{title}</h4>
        <table className="table table-responsive-md status-table highlight-on-hover mb-0">
          <thead>
            <tr>
              <th>Date</th>
              <th className="text-center">Location</th>
              <th>Probe Server</th>
              <th className="text-center">Location's State Changed To</th>
              <th>Details</th>
              <th className="text-center"># Locations Down</th>
              <th className="text-center">Overall Check State</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.props.recentAlerts.slice(0, 10).map(this.renderOneRecentAlert)}</tbody>
          {moreRows}
        </table>
      </div>
    );
  }

  renderOneRecentAlert(gas, idx, arr) {
    const isLastEntry = idx === arr.length - 1;
    const isInitialGlobalAlertState = isLastEntry && gas.state_is_up && gas.state_has_changed && !gas.alert;
    const hasAlert = !!gas.alert;
    const alert = gas.alert || {};
    const monitoringServer = hasAlert && alert.monitoring_server ? alert.monitoring_server : {};
    const monitoringServerTooltip = `<span className=&quot;text-nowrap&quot;>${monitoringServer.address_ipv4}</span><br><span className=&quot;text-nowrap&quot;>${monitoringServer.address_ipv6}</span>`;
    const updown = gas.state_is_up ? 'up' : 'down';
    const tooltip = `${updown.toUpperCase()} alert notifications sent.`;

    let checkState = '';
    if (isInitialGlobalAlertState) {
      checkState = 'pending';
    } else if (gas.state_has_changed) {
      checkState = (
        <span data-toggle="tooltip" data-html="false" tabIndex="0" className="tooltip-indicator" title={tooltip}>
          <strong className="font-13">{updown.toUpperCase()}</strong>
        </span>
      );
    }

    return (
      <tr key={gas.id}>
        <td className="text-nowrap">
          <span
            className={'status ' + (gas.state_has_changed ? updown : '')}
            data-toggle="tooltip"
            title={gas.state_has_changed ? tooltip : ''}
          ></span>
          <span> {Formatter.shortDateTime(gas.created_at)}</span>
        </td>
        <td className="text-center">{monitoringServer.location}</td>
        <td>
          <span
            data-toggle="tooltip"
            data-html="true"
            tabIndex="0"
            className="tooltip-indicator"
            title={monitoringServerTooltip}
          >
            {monitoringServer.name}
          </span>
        </td>
        <td className={'text-center text-' + Formatter.alertStateCSSClass(alert.state)}>{alert.state}</td>
        <td className="text-word-wrap">{alert.output}</td>
        <td className="text-center">{gas.num_locations_down}</td>
        <td className="text-center">{checkState}</td>
        <td>
          {!gas.state_is_up && gas.state_has_changed ? (
            <button
              onClick={this.handleAlertDetailsClick.bind(null, gas.id)}
              className="btn btn-outline-primary btn-xs"
            >
              Alert Details
            </button>
          ) : null}
        </td>
      </tr>
    );
  }

  renderErrorOrProgress() {
    if (this.state.tracerouteStatus === TaskStatus.FAILED) {
      return (
        <div className="alert alert-danger" role="alert">
          <strong>Error:</strong> {this.state.tracerouteError}
        </div>
      );
    } else if (this.state.tracerouteStatus == TaskStatus.NEW || this.state.tracerouteStatus == TaskStatus.RUNNING) {
      const isInQueue = this.state.tracerouteStatus === TaskStatus.NEW;
      const max = 120000;
      const progress = Math.min((this.loadTimer / max) * 100.0, 98);

      return (
        <div>
          <p className="mb-1">
            Please wait...
            <strong>{isInQueue ? 'Task is waiting in queue.' : 'Task is in progress.'}</strong>
          </p>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow={isInQueue ? 0 : progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{width: (isInQueue ? 0 : progress) + '%'}}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  renderTraceroute() {
    if (!this.props.tracerouteLocations || this.props.tracerouteLocations.length === 0) {
      return null;
    }

    if (!this.props.tracerouteEnabled) {
      return (
        <div className="traceroute white-block white-block-border white-block-divider p-4 py-5">
          <h4 className="font-weight-medium mb-3">Traceroute</h4>
          {/* eslint-disable-next-line max-len */}
          Use{' '}
          <a
            target="_blank"
            href="https://support.uptime.com/hc/en-us/articles/360004800699-Using-Real-Time-Analysis-with-Uptime-com#traceroute"
          >
            traceroute
          </a>{' '}
          to detect connection anomalies between Uptime.com and your service. Contact{' '}
          <a href={this.props.urls.contact}>support@uptime.com</a> to add traceroute to your subscription.
        </div>
      );
    }

    const formLink = prepareFormLink(this, 'formData', null);
    var output = null;
    var isRunning =
      this.state.tracerouteStatus === TaskStatus.NEW || this.state.tracerouteStatus === TaskStatus.RUNNING;
    var form = null;
    if (this.state.tracerouteStatus) {
      output = (
        <textarea
          id="terminal"
          className="form-control mt-3 linux-terminal-colors"
          readOnly={true}
          value={this.state.tracerouteResult}
          rows="15"
        ></textarea>
      );
    }
    if (!isRunning) {
      form = (
        <div className="form-row align-items-center small">
          <div className="col-auto">
            <div className="form-group">
              <label>Run a traceroute from this location:</label>
            </div>
          </div>
          <div className="col-auto">
            <SelectBox
              fieldName="traceroute_location"
              choices={this.props.tracerouteLocations}
              allowEmpty={false}
              formLink={formLink}
            />
          </div>
          <div className="col-auto">
            <div className="form-group">
              <button
                type="submit"
                onClick={this.handleTracerouteStart}
                className="btn btn-primary"
                disabled={isRunning}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="traceroute white-block white-block-border white-block-divider p-4 py-5">
        <h4 className="font-weight-medium mb-3">Traceroute</h4>
        {form}
        {this.renderErrorOrProgress()}
        {output}
      </div>
    );
  }
}
